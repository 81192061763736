import request from '@/plugins/axios'

// 查询
export function searchLog2(current, limit, data) {
  return request({
    url: `/blastPhone/searchLog2/${current}/${limit}`,
    method: 'post',
    data
  })
}
