<template>
  <div class="integrate-page">
    <div class="top">
      <el-date-picker
        v-model="searchForm.startDate"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择开始时间"
        size="small"
      ></el-date-picker>
      <el-date-picker
        v-model="searchForm.endDate"
        type="date"
        value-format="yyyy-MM-dd"
        style="margin-left:10px"
        size="small"
        placeholder="选择结束时间"
      ></el-date-picker>

      <el-button
        type="primary"
        size="mini"
        style=" margin-left:10px"
        @click="searchFun"
      >
        查询
      </el-button>
      <el-button
        class="el-icon-refresh-left"
        size="mini"
        style=" margin-left:10px"
        @click="refresh"
      >
        刷新
      </el-button>
      <el-button
        class="el-icon-download"
        type="success"
        size="mini"
        style=" margin-left:10px"
        @click="exportFun"
        :loading="exportLoading"
        v-if="userInfo.isYs == 1"
      >
        导出
      </el-button>
    </div>
    <div class="child" v-loading="loading">
      <el-table
        style="margin-bottom:10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
        header-row-class-name="tableheader"
        :data="list"
        border
        :height="windowsHeight - 220"
      >
        <el-table-column prop="user_id" label="Telegram uid"></el-table-column>
        <el-table-column prop="end_time" label="提交时间"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
      </el-table>
      <pagination
        :total="total"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :margin="0"
        :sizesArr="[30, 50, 100]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>

    <!-- 手机详情弹框 -->
    <userDetail :placeVisible="placeVisible" @closePlace="closePlace" />
  </div>
</template>

<script>
import { searchLog2 } from '@/api/dropCheck/record'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
export default {
  name: 'CheckRecord',
  data() {
    return {
      loading: false,
      list: [],
      current: 1,
      limit: 30,
      total: 0,
      searchForm: {
        endDate: '',
        startDate: ''
      },
      placeVisible: false,
      exportLoading: false
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    },
    userInfo() {
      return this.$store.state.user.userInfo || {}
    }
  },

  created() {
    this.getPage()
  },
  methods: {
    // 关闭弹框
    closePlace() {
      this.placeVisible = false
    },
    searchFun() {
      this.current = 1
      this.getPage()
    },
    refresh() {
      this.getPage()
    },
    getPage() {
      this.loading = true
      searchLog2(this.current, this.limit, this.searchForm).then(data => {
        this.list = data.items
        this.total = data.all_count
        this.loading = false
      })
    },
    currentChange(val) {
      this.current = val
      this.getPage()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getPage()
    },
    exportFun() {
      this.exportLoading = true
      searchLog2(1, 100000, this.searchForm)
        .then(res => {
          let data = res.items.map(item => {
            let obj = {
              uid: item.user_id,
              提交时间: item.end_time,
              手机号: item.phone
            }
            return obj
          })
          this.downloadCSV(data)
          this.exportLoading = false
        })
        .catch(() => {
          this.exportLoading = false
        })
    },
    // Function to download CSV file
    downloadCSV(data) {
      // 将 JSON 数据转换为工作表
      const worksheet = XLSX.utils.json_to_sheet(data)
      // 创建一个新的工作簿
      const workbook = XLSX.utils.book_new()
      // 将工作表添加到工作簿
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      // 生成 Excel 文件的二进制数据
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      })
      // 创建 Blob 对象
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
      // 使用 file-saver 库保存文件

      saveAs(blob, `${new Date().toLocaleDateString()}.xlsx`)
    }
  }
}
</script>

<style lang="scss" scoped>
.integrate-page {
  padding: 10px;
  width: 100%;
  height: calc(100vh - 80px);
  background: #f0f2f5;
  .top {
    padding: 10px;
    background: white;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    border-radius: 5px;
  }
  .child {
    margin-top: 5px;
    padding: 10px;
    padding-bottom: 1px;
    background: white;
    box-shadow: rgb(216 217 217) 0px 1px 3px 0px;
    border-radius: 5px;
  }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
